import React from 'react';
import {Col, Row} from 'reactstrap';

class RobotSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            AboutList: [{
                "icon": "mdi mdi-lightbulb",
                "title": "Creative Design",
                "content": "Nemo enim ipsam voluptatem quia voluptas sit aspernatur at aut odit aut fugit sed quia consequuntur magni.",
            }, {
                "icon": "mdi mdi-projector-screen",
                "title": "Strategy Solutions",
                "content": "Nemo enim ipsam voluptatem quia voluptas sit aspernatur at aut odit aut fugit sed quia consequuntur magni.",
            }, {
                "icon": "mdi mdi-nature",
                "title": "Dynamic Growth",
                "content": "Nemo enim ipsam voluptatem quia voluptas sit aspernatur at aut odit aut fugit sed quia consequuntur magni.",
            },
            ]
        };
    }

    render() {

        return (
            <React.Fragment>
                <section className="section bg-robot" id="robot">
                    <div className="container">
                        <Row>
                            <Col lg="4">
                                <div className="title-heading mb-5">
                                    <h3 className="text-dark mb-1 font-weight-light text-uppercase">Fé our GenAI robot</h3>
                                    <div className="title-border-simple position-relative"></div>
                                </div>
                            </Col>
                        </Row>

                        <Row className="align-items-center mt-5">
                            <Col md="6">
                                <div className="robot-desc">
                                    <h3 className="text-dark mb-3 font-weight-light">Feed our robot</h3>
                                    <p className="text-muted f-15">
                                        We have; with help, built the UK's first AI-Powered funding research robot to assist you with prospect research and discovery. Fé will comb through all the continuously updated information within the fundify system, providing you with new opportunities directly related to the queries you enter. She can also look outside to find information on the web and social media, so really is a one-stop shop for all funding information.
                                    </p>
                                    <p className="text-muted f-15">
                                        You can sign up for Fé below. As a new fundraising tool we have provided some example prompts to help new users and there will be a dedicated section within the community where users can share prompts that have worked well for them and we will also have some more technical computer and data scientists visiting from time to time to lend a hand in creating ultimate prompts. <a href="https://docs.google.com/forms/d/1YOJilR5asC46mOgIoDNTfhi1hUoxL9kpeoQMbRg8EfY" target="_blank" rel="noopener noreferrer">Sign up here</a>
                                    </p>
                                </div>
                            </Col>

                            <Col md="6">
                            <div>
        <img src="images\robot.jpg" className="img-fluid mx-auto d-block" alt="robot"/>
      </div>
        </Col>
                            </Row>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
export default RobotSection;
