import React from 'react';
import {Col, Row} from 'reactstrap';

class PricingSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            PlaneList: [{
                "name": "Community+ (For Organisations)",
                "price": "£40",
                "is_active": false,
                "benefits": [
                    "Community Edition features plus:",
                    "Enhanced Data",
                    "Saved Searches",
                    "Advanced Personalisation",
                    "Notifications & Reminders",
                    "For organisations"
                ]
            }, {
                "name": "Premium (For Infrastructure Organisations)",
                "price": "£TBC",
                "is_active": false,
                "benefits": [
                    "Community+ Edition features plus:",
                    "Access to additional data",
                    "Impact metrics",
                    "Reach",
                    "Input on Product Roadmap"
                ]
            }
            ]
        };
    }

    render() {
        return (
            <React.Fragment>
                <section className="section" id="pricing">
                    <div className="container">
                        <Row>
                            <Col lg="12">
                                <div className="title-heading mb-5">
                                    <h3 className="text-dark mb-1 font-weight-light text-uppercase">Our Pricing</h3>
                                    <div className="title-border-simple position-relative"></div>
                                </div>
                            </Col>
                        </Row>
                        <div className="row">
                            {this.state.PlaneList.map((plan, index) => {
                                return <Col key={plan.name} lg="4" md="6">
                                    <div className={plan.is_active ? "pricing-box active mt-4" : "pricing-box mt-4"}>
                                        <div className="price bg-light position-relative p-4">
                                            <div className="float-left">
                                                <h5 className="text-dark title mt-2 font-weight-normal f-18 mb-0">{plan.name}</h5>
                                            </div>
                                            <div className="">
                                                <h2 className="text-dark font-weight-normal text-right mb-0">{
                                                    plan.price !== 'FREE' ? plan.price : 'FREE'}</h2>
                                            </div>
                                        </div>
                                        <div className="p-4 pricing-list">
                                            <ul className="list-unstyled mb-0" style={{minHeight: '200px'}}>
                                                {plan.benefits.map((benefit) =>
                                                    <li key={benefit} className="text-muted f-14">{benefit} </li>
                                                )}
                                            </ul>
                                        </div>
                                        <div className="pl-4 mb-4 mt-2">
                                            <a
                                                href={plan.price !== 'FREE'
                                                    ? "https://bit.ly/we-are-fundify"
                                                    : 'https://v2.wearefundify.org.uk?utm_source=landing-page'
                                                }
                                                className={plan.is_active ? "btn btn-outline btn-sm active" : "btn btn-outline btn-sm"}
                                            >
                                                Sign Up
                                            </a>
                                        </div>
                                    </div>
                                </Col>
                            })}
                        </div>
                        <Row>
                            <Col lg="12">
                                <div className="title-heading mb-5">
                                    <h3 className="text-dark mb-1 font-weight-light text-uppercase">Join Us</h3>
                                    <div className="title-border-simple position-relative"></div>
                                </div>
                            </Col>
                            <h6 className="text-dark mb-1 font-weight-light">
                                If you want to pay less £££, work with us on making Fundify a better system. Contact us
                                to discuss a discount.
                            </h6>
                            <hr/>
                            <h6 className="text-dark mb-1 font-weight-light">
                                Loving the platform? Bring your friends and colleagues along for everyone that signs up
                                you will receive 25% credit off your next subscription.
                            </h6>
                        </Row>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default PricingSection;
